.user-grid{
    display: grid;
    grid-template-rows: 1fr;
    grid-template-columns:  9fr 9fr 4fr 1fr 1fr 1fr;
    grid-gap: 3px;

    padding-top: 8px;
}
.user-grid .cell{
    font-size: 0.8cm;
    background-color: #222!important;
    border: 1px solid #333;
    padding: 5px 50px;
    border-radius: 15px;
    cursor: pointer;
    margin: 0px;
}
.user-grid .cell-edit{
    background-image: url("/icons/edit.svg");
    background-size: 1cm;
    background-repeat: no-repeat;
    background-position: center center;
}

.user-grid .cell-project{
    background-image: url("/icons/prefs.png");
}
.user-grid .cell-del{
    background-image: url("/icons/remove.svg");
    background-size: 1cm;
    background-repeat: no-repeat;
    background-position: center center;
}
.user-grid .edit-icon{
    margin: 9px 0px;
    background-color: transparent;
}