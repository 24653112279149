.project-report-grid{
    display: grid;
    grid-template-rows: 1fr;
    grid-template-columns:  6fr 4fr 3fr ;
    grid-gap: 3px;

    padding-top: 8px;
}
.report .cell{
    font-size: 0.8cm;
    background-color: #222!important;
    border: 1px solid #333;
    padding: 5px 50px;
    border-radius: 15px;
    cursor: pointer;
    margin: 0px;
}
.report .cell-head{
    font-weight: bolder;
    background-color: #444!important;
    box-shadow: 2px 8px 11px 0px black;
    text-shadow: -3px 5px 5px black;
}