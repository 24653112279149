.action-list-grid {
    display: grid;
    grid-template-rows: 1fr;
    grid-template-columns: 2fr 3fr 3fr 1fr 5fr 2fr;
    grid-gap: 3px;
    border: 1px solid #333;
    padding-top: 8px;
}

.row-dark {
    background-color: #28282f !important;
}

.row-light {
    background-color: #2f3238 !important;
}

.week-end {
    background-color: #311 !important;
}

.edit-icon {
    width: 25px;
    margin-right: 25px;
    cursor: pointer;
    float: left;
    display: inline;
}

.action-list-grid img {
    background-color: transparent !important;
}

.cell {
    /*border: 1px solid #333;*/
    height: 55px;
    vertical-align: bottom;
    text-align: left;
    background-color: transparent ;

}

.cell-head {
    font-size: 2em;
    color: #888;
    /*font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";*/
    font-weight: bolder;
}

.new-icon {
    grid-column-start: 6;
    grid-column-end: 7;
    margin-bottom: 8px;
}

.date-empty.work {
    background-repeat: no-repeat;
    background-position: right center;
    background-image: url("/icons/battery_empty.svg");
}

.date-half.work {

    background-repeat: no-repeat;
    background-position: right center;
    background-image: url("/icons/battery_half.svg");
}
.date-full.work {

    background-repeat: no-repeat;
    background-position: right center;
    background-image: url("/icons/battery_full.svg");
}

.date-over.work {

    background-repeat: no-repeat;
    background-position: right center;
    background-image: url("/icons/battery_over.svg");
}

.date-half.week-end, .date-full.week-end, .date-over.week-end{

    background-repeat: no-repeat;
    background-position: right center;
    background-image: url("/icons/battery_over.svg");
}



.date {
    display: table-cell;
    vertical-align: middle;
    /*border-right: 1px solid #999;*/

    padding: 4px;
}

.date1 {
    grid-column-start: 1;
    grid-column-end: 2;
    grid-row-start: 1;
    grid-row-end: 2;
}

.date2 {
    grid-column-start: 1;
    grid-column-end: 2;
    grid-row-start: 1;
    grid-row-end: 3;
}

.date3 {
    grid-column-start: 1;
    grid-column-end: 2;
    grid-row-start: 1;
    grid-row-end: 4;
}

.date4 {
    grid-column-start: 1;
    grid-column-end: 2;
    grid-row-start: 1;
    grid-row-end: 5;
}

.date5 {
    grid-column-start: 1;
    grid-column-end: 2;
    grid-row-start: 1;
    grid-row-end: 6;
}

.date6 {
    grid-column-start: 1;
    grid-column-end: 2;
    grid-row-start: 1;
    grid-row-end: 7;
}

.date7 {
    grid-column-start: 1;
    grid-column-end: 2;
    grid-row-start: 1;
    grid-row-end: 8;
}

.date8 {
    grid-column-start: 1;
    grid-column-end: 2;
    grid-row-start: 1;
    grid-row-end: 9;
}

.date9 {
    grid-column-start: 1;
    grid-column-end: 2;
    grid-row-start: 1;
    grid-row-end: 10;
}

.date10 {
    grid-column-start: 1;
    grid-column-end: 2;
    grid-row-start: 1;
    grid-row-end: 11;
}

.date11 {
    grid-column-start: 1;
    grid-column-end: 2;
    grid-row-start: 1;
    grid-row-end: 12;
}

.date12 {
    grid-column-start: 1;
    grid-column-end: 2;
    grid-row-start: 1;
    grid-row-end: 13;
}


.date3 {
    grid-column-start: 1;
    grid-column-end: 2;
    grid-row-start: 1;
    grid-row-end: 4;
}

.date3 {
    grid-column-start: 1;
    grid-column-end: 2;
    grid-row-start: 1;
    grid-row-end: 4;
}

.date3 {
    grid-column-start: 1;
    grid-column-end: 2;
    grid-row-start: 1;
    grid-row-end: 4;
}

.date3 {
    grid-column-start: 1;
    grid-column-end: 2;
    grid-row-start: 1;
    grid-row-end: 4;
}

.date3 {
    grid-column-start: 1;
    grid-column-end: 2;
    grid-row-start: 1;
    grid-row-end: 4;
}


