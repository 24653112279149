.App {
  text-align: center;
  background: #282c34;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}
#root{ background: #282c34 ;}
.App-header {
  background-color: #282c34;
  min-height: 45px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}
body, .App-sidebar, .App-body *, nav.sidebar, div.row,.container-fluid{
  background: #282c34 ;
  color: #808090;
}
.App-sidebar{ border-right: 2px solid #383c44; }
.App-body{
  background: #282c34;
  /*margin-top: 30px;*/
}

a.nav-link:hover{
  color: #8aa;
  font-weight: bold;
}
a.nav-link {
  font-size: 1.5rem;
  text-align: right;
}
a.nav-link.active{
  text-shadow: -8px 10px 8px #18181c;
  color: #AAA;
  font-weight: bolder;
}

a.navbar-brand{
  color: #AAC!important;
  font-size: 2rem!important;
  font-weight: bold;
}



.form-group label{
  padding-right: 20px;
}
@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
