a.pr-grid{
    display: grid;
    grid-template-columns: 2fr 3fr;
    grid-gap: 3px;
    border: 1px solid #333;
    padding-top: 8px;
}
.pr-cell{
    padding: 3px 12px 2px 5px;

    /*border: 1px solid #333;*/
    /*height: 55px;*/
    vertical-align: bottom;

    /*background-color: transparent ;*/


    /*background-color: #222;*/
    /*border: 1px solid #333;*/
    /*padding: 5px 50px;*/
    border-radius: 45px;
    cursor: pointer;
    margin: 0px;
}
.pr-cell.pr-client{
    /*border-right: 2px solid #111;*/

}