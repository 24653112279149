.p-report-task-grid{
    display: grid;
    grid-template-columns:  8fr 12fr 4fr 8fr ;
    grid-gap: 9px;

    padding-top: 8px;
    padding-left: 9px;
    /*border: 1px solid #88a;*/
}
.p-report-task-grid .cell,.p-report-day-grid .cell, .p-report-full-grid .cell{

    font-size: 0.8cm;
    background-color: #222;
    border: 1px solid #333;
    padding: 5px 50px;
    border-radius: 15px;
    cursor: pointer;
    margin: 0px;
}
.p-report-day-grid{
    display: grid;
    grid-template-columns:  8fr 3fr 1fr 8fr ;
    grid-gap: 9px;

    padding-top: 8px;
    padding-left: 9px;
    /*border: 1px solid #88a;*/
}
.p-report-full-grid{
    display: grid;
    grid-template-right: 1fr;
    grid-template-columns:  1fr 7fr 8fr 10fr 1fr 1fr ;
    grid-gap: 9px;

    padding-top: 8px;
    padding-left: 9px;
    /*border: 1px solid #88a;*/
}
.project-filter-report hr{
    border-top: 2px solid rgba(0,0,0,0.2) ;
}
.p-report-full-grid .cell.cell-description{
    font-size: 0.5cm;
}