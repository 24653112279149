.datePanel {
    display: grid;
    grid-template-rows: 1fr;
    grid-template-columns: 3fr 9fr 3fr;
    background-color: #52575B;
}
.datePanel a.startDate{
    text-align: left;
}
.datePanel a.nav-link{
    /*border: 1px solid #33373E;*/
    /*border-radius: 8px;*/
    background-color: #202025;
}
.datePanel div{
    /*border: 1px solid red;*/
    height: 50px;
    /*background: #444!important;*/
}

